import React, { useState, useEffect } from 'react';
import Dropdown from "../../categories/CategoryDropdown";
import styles from './CreateRma.module.css';
import { getProdName, getWarrantyDetails } from "../../../service";
import {  faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    MDBRow,
    MDBCol,
    MDBInput,
    MDBTextArea,
    MDBBtn,
    MDBContainer,
    MDBAccordion,
    MDBAccordionItem,
    MDBCard
} from 'mdb-react-ui-kit';

const VALIDATION_BUTTON_TEXT = 'Validate RMA Request';
const PRODUCT_VALIDATION_HEADER = 'Product and Validation';
const RMA_DETAILS_HEADER = 'RMA Details';
const ERROR_DETAILED_DESCRIPTION_TOO_SHORT = 'Description seems too short';
const ERROR_DETAILED_DESCRIPTION_NONE = 'Please enter a detailed description';
const ERROR_DETAILED_DESCRIPTION_TOO_LONG = 'Detailed description should be a maximum of 500 characters per unit';
const SUBMIT_RMA = 'Submit RMA Request';
const ADD_RMA = 'Add to RMA';
const SECURRANTY_URL = 'https://securranty.com/';
const CreateRma = ({ props, user, product, addToRma }) => {
    const [serialNumber, setSerialNumber] = useState(product && product.SerialNumber ? product.SerialNumber : null);
    const [categoryChoice, setCategoryChoice] = useState({ CategoryID: 0, Category: '' });
    const [rmaProduct, setRmaProduct] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [active, setActive] = useState('validateProdItem');
    const [rmaNumber, setRmaNumber] = useState(addToRma && addToRma.RmaNumber ? addToRma.RmaNumber: null);
    const [rma, setRma] = useState(addToRma ?addToRma:null);
    const [refno, setRefno] = useState(addToRma && addToRma.REFNO ? addToRma.REFNO : null);
    const [submitButtonText, setSubmitButtonText] = useState(SUBMIT_RMA);
    const [issues, setIssues] = useState([]);
    const [issueChoice, setIssueChoice] = useState({ IssueID: 0, Issue: '' });
    const [rmaChoice, setRmaChoice] = useState({ ID: 0, Value: '' });
    const [detailedDescription, setDetailedDescription] = useState('');
    const [detailedDescriptionError, setDetailedDescriptionError] = useState(null);
    const [specialInstructions, setSpecialInstructions] = useState(null);

    useEffect(() => {
        console.log(`checkFormValid: `, issueChoice, rmaChoice, detailedDescriptionError, detailedDescription);
        checkDetails(detailedDescription);
        if (issueChoice.IssueID !== 0 && rmaChoice.ID !== 0 && !detailedDescriptionError && detailedDescription) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [issueChoice, rmaChoice, detailedDescription, detailedDescriptionError]);
    const [errorSerial, setErrorSerial] = useState(null);
    const [doShowSeccuranty, setDoShowSeccuranty] = useState(false);
    const [categories, setCategories] = useState(null);
    let btnClass = `mb-4 px-5 ${(props.doOutlineButton ? `btn-outline-${props.buttonColour}` : '')}`;

    /**
     * Takes ID and Name and sets the category choice state
     * @param {any} id
     * @param {any} name
     */
    const updateCategoryChoice = (id, name) => {
        console.log(`updateCategoryChoice: ${id} ${name}`);
        setCategoryChoice({ CategoryID: id, Category: name });
    };


    /**
     * Used for onChange in text box which feeds argument, checks validity and sets state
     * @param {any} e
     */
    function updateSerial(e) {
        setSerialNumber(e.target.value);
        const serialRegex = /^[A-Za-z0-9]+$/;
        console.log(`updateSerial: ${e.target.value}`, 'Pass RegEx:', (serialRegex.test(e.target.value)), 'Pass Length: ', !(e.target.value.length < 5));
        if (!serialRegex.test(e.target.value) || e.target.value.length < 4) {
            setErrorSerial('Please enter a valid serial number');
        } else {
            setSerialNumber(e.target.value);
            setErrorSerial(null);
            return true;
        }

        if (doShowSeccuranty) {
            setDoShowSeccuranty(false);
        }
    }

    if (!categories) getCategories();

    function getCategories() {
        if (!categories) {
            let items = [];
            console.log(`Getting Categories...`);
            fetch(`${props.baseUrl}categories/`, props.requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    let parsedRes = JSON.parse(result);
                    for (var item of parsedRes.categories) {
                        if (!items.includes({ ID: item.CategoryID, Value: item.Category })) {
                            items.push({ ID: item.CategoryID, Value: item.Category });
                        }
                    }

                    setCategories(items);
                })
                .catch((error) => console.error('There was an issue creating the select items: ' + error));
        }
    }

    const setRmaValid = (valid) => {        
        setIsValid(valid);
        if (valid) {
            console.log(`[CreateRMA][setRmaValid]> Valid RMA... Getting Issues for Category: ${categoryChoice}.`);

            /* Get Issues for chosen Category */
            let items = [];
            try {
                fetch(`${props.baseUrl}issues/${categoryChoice.CategoryID}`, props.requestOptions)
                    .then((response) => response.text())
                    .then((result) => {
                        console.log(`[CreateRMA][setRmaValid]> Got Issues: ${result}`);

                        let parsedRes = JSON.parse(result);
                        for (var item of parsedRes.issues) {
                            if (!items.includes({ ID: item.IssueID, Value: item.Issue })) items.push({ ID: item.IssueID, Value: item.Issue });
                        }
                        if (items) setIssues([...items]);
                        setActive('createRmaItem');
                    })
                    .catch((error) => console.error('There was an issue creating the select items: ' + error));
            } catch (err) {
                console.log(`[CreateRMA][setRmaValid]> There was an issue getting the issues: `, err);
            }
        }
    }

    const updateChoice = (id, name) => {
        console.log(`updateIssueChoice: ${id} ${name}`);
        setIssueChoice({ IssueID: id, Issue: name });
    };

    const updateRmaChoice = (id, name) => {
        console.log(`updateRmaChoice: ${id} ${name}`);
        setRmaChoice({ ID: id, Value: name });
    };

    const updateDetailedDescription = (e) => {
        console.log(`updateDetailedDescription: ${e.target.value}`);
        setDetailedDescription(e.target.value);
        checkDetails(e.target.value);
    }

    const checkDetails = (details) => {
        if (!details) {
            setDetailedDescriptionError(ERROR_DETAILED_DESCRIPTION_NONE);
        } else if (details.length < 5) {
            setDetailedDescriptionError(ERROR_DETAILED_DESCRIPTION_TOO_SHORT);
        } else if (details.length > 500) {
            setDetailedDescriptionError(ERROR_DETAILED_DESCRIPTION_TOO_LONG);
        } else {
            setDetailedDescriptionError(null);
        }
    }

    const updateSpecialInstructions = (e) => {
        console.log(`updateSpecialInstructions: ${e.target.value}`);
        setSpecialInstructions(e.target.value);

    }
       

    const validate = (event) => {
        if (event) {
            event.preventDefault();
        }
        if (categoryChoice && serialNumber && !errorSerial) {
            const requestOptions = {
                method: "GET",
                headers: props.header,
                redirect: "follow",
                changeOrigin: true
            };
            console.log(`validate section:`, categoryChoice, serialNumber);
            try {
                fetch(`${props.baseUrl}product/${serialNumber}`, requestOptions)
                    .then((response) => response.text())
                    .then((result) => {
                        /* Validate Entries: Confirm Serial number is valid for account, get place of purchase */
                        result = JSON.parse(result);
                        if (result && result.status && result.status === 200) {
                            console.log(`[Validation][validate][validationSection][SUCCESS]> ${JSON.stringify(result)}`);

                            console.log(`[Validation][validate][validationSection][SUCCESS]> Getting Warranty Details...`);
                            getWarrantyDetails(result.product);

                            console.log(`[Validation][validate][validationSection][SUCCESS]> Setting RMA Product...`, result.product);
                            setRmaProduct(result.product ? result.product : '');

                            if (result.product && result.product.IsCovered) {
                                setSerialNumber('');
                                setRmaValid(true);
                                console.log(`[Validation][validate][validationSection][SUCCESS]> Resetting form....`);
                                document.getElementById('rmaValidationForm').reset();
                            } else {
                                console.log(`[Validation][validate][validationSection][SUCCESS]> Alert Serial Error....`);
                                setErrorSerial('This product does not appear to be covered currently');
                                console.log(`[Validation][validate][validationSection][SUCCESS]> Check for show securranty....`);
                                setDoShowSeccuranty(props.isAdpCustomer && !result.product.IsADP);
                                console.log(`[Validation][validate][validationSection][SUCCESS]> Set RMA invalid....`);
                                setRmaValid(false);
                            }
                        } else {
                            setRmaValid(false);
                            console.log(`[Validation][validate][validationSection][FAILED]> ${JSON.stringify(result)}`);
                            if (result && result.message) {
                                console.log(`[Validation][validate][validationSection][SUCCESS]> Alert Serial Error Message:`, result.message);
                                setErrorSerial(result && result.message ? result.message.toString() : 'Unable to validate serial number.');

                                console.log(`[Validation][validate][validationSection][SUCCESS]> Check for show securranty message... props.isAdpCustomer: ${props.isAdpCustomer}`);
                                setDoShowSeccuranty(props.isAdpCustomer);
                            } else {
                                console.log(`[Validation][validate][validationSection][SUCCESS]> Set Serial Error....`);
                                setErrorSerial('There was an issue validating the serial number...');
                                console.log(`[Validation][validate][validationSection][SUCCESS]> Check for show securranty message no result...`);
                                setDoShowSeccuranty(props.isAdpCustomer);
                            }
                        }
                    })
                    .catch((error) => {
                        console.error('[Validation][validate][validationSection][ERROR]> There was an issue checking the serial number: ' + error);
                        setErrorSerial(error.toString());
                    });
            } catch (err) {
                console.error('[Validation][validate][validationSection][ERROR]> There was an fetching the data: ' + err);
            }
        }
    }

    const submitRma = (event) => {
        if (event) {
            event.preventDefault();
        }
        console.log(`Submit RMA Attempt`);
        if (isFormValid) {
            console.log(`Submit RMA Attempt Valid`);
            let body = {
                RMA: {
                    REFNO: rma && rma.REFNO ? rma.REFNO : null,
                    RmaNumber: rmaNumber,
                    CompanyID: user.CompanyID,
                    InvoiceNumber: rmaProduct.OrderNumber,
                    SerialNumber: rmaProduct.SerialNumber,
                    Notes: `Contact Name: ${user.FullName} | Email: ${user.UserName} | ${(rmaProduct.IsADP && props.isAdpCustomer ? "<|[{(*ADP*)}]|> " : "")}${(specialInstructions ? specialInstructions:'')}`,
                    Reason: `${(rmaProduct.IsADP ? "<|[{(*ADP*)}]|> " : "")}${(detailedDescription ? detailedDescription: '')}`,
                    ProductType: rmaProduct.ProductType,
                    RequestType: rmaChoice.Value,
                    CategoryID: categoryChoice.CategoryID,
                    IssueID: issueChoice.IssueID,
                    Description: rmaProduct.Description,
                },
                Product: rmaProduct,
                UserName: user.UserName,
                Token: user.Token,
                Page: 'submitRma',
                UserAccess: user.UserAccess,
                Site: props.brand,
                User: user,
                SalesEmail: props.mainSalesEmail,
                MainEmail: props.mainEmail,
                RegisteredURL: window.location.href,
            }
            const requestOptions = {
                method: "post",
                headers: props.header,
                redirect: "follow",
                changeOrigin: true,
                body: JSON.stringify(body),
            };

            fetch(`${props.baseUrl}RMA`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    console.log(`[CreateRMA][submitRma]> Result: ${result}`);
                    /* Validate Entries: Confirm Serial number is valid for account, get place of purchase */
                    result = JSON.parse(result);
                    if (result && result.status && result.status === 200) {
                        console.log(`[CreateRMA][submitRma][SUCCESS]> ${result}`);
                        alert(result.message);
                        /* Clear Current RMA Details, except for number to allow adding to RMA */
                        document.getElementById('rmaDetailsForm').reset();
                        setRma(result.rma);
                        setRmaProduct('');
                        setRmaNumber(result.rma.RmaNumber);
                        setSerialNumber(null);
                        setSubmitButtonText(ADD_RMA);
                        setRefno(result.rma.REFNO);
                        setCategoryChoice({ CategoryID: 0, Category: '' });
                        setIssueChoice({ IssueID: 0, Issue: '' });
                        setDetailedDescription(null);
                        setSpecialInstructions(null);
                        setActive('validateProdItem');
                        setIsValid(false);
                        setIsFormValid(false);
                    } else {
                        console.log(`[CreateRMA][submitRma][FAILED]> ${result}`);
                        if (result && result.message) {
                            alert(result.message);
                        } else {
                            alert('There appears to have been an error creating the RMA...');
                        }
                    }
                })
                .catch((error) => console.error('[CreateRMA][submitRma][ERROR]> There was an error creating the RMA: ' + error));
        }
        else {
            console.log(`Submit RMA Attempt Invalid...`);
        }
    }

    return (
        <React.Fragment>
            <MDBContainer style={styles} className="my-5">
                <MDBCard>
                    <MDBRow className='g-0'>
                        <MDBCol md='12'>
                            <MDBAccordion active={active} onChange={(itemId) => setActive(itemId)}>
                                <MDBAccordionItem key={'validateProdItem'} collapseId={'validateProdItem'} 
                                    headerTitle={<span style={{ color: props.textIconColour, }}
                                        className="h2 fw-bold mb-0 mx-3">{`${(refno ? `+${rmaNumber} ` : '')}${PRODUCT_VALIDATION_HEADER}`}{isValid ?
                                            <FontAwesomeIcon style={{ color: props.passIconColour, }} size="1x" icon={faCheckCircle} className="mx-3" /> : ''}
                                        {isValid && rmaProduct && rmaProduct.IsADP ? <img src={props.shieldGuardImage} alt='CNB Shield Guard Logo' style={{
                                            height: 'auto', maxHeight: '45px',
                                            'width': 'auto',
                                            objectFit: 'contain'
                                        }} /> : ''}
                                    </span>
                                        
                                    }>

                                    <form id={'rmaValidationForm'}>
                                        <MDBInput maxLength={50} wrapperClass='mb-4' label='Serial Number of Unit'
                                            id='formControlSerial' type='text' size="lg"
                                            onChange={updateSerial} style={errorSerial ? props.errorStyle : null}
                                            defaultValue={serialNumber ? serialNumber : ''}
                                        >
                                            <div id='serialErrorLabel' className='form-helper'>
                                                {errorSerial}
                                            </div>
                                        </MDBInput>
                                        {categories ? <Dropdown options={categories} updateChoice={updateCategoryChoice} chooseWhat={'Choose a Category'} />
                                            : null}
                                        <MDBBtn className={btnClass} color={props.buttonColour} size='lg' onClick={validate}>{VALIDATION_BUTTON_TEXT}</MDBBtn>
                                        {doShowSeccuranty ? <>
                                            <p>{'If you purchased your ADP coverage prior to August 1st 2024 for '}<b>{serialNumber ? serialNumber : ''}</b> {' your coverage may be found here: '} <a href={SECURRANTY_URL} target="_blank" rel="noopener noreferrer"> {'Securranty'}</a> </p>
                                        </> : null}
                                    </form>

                                </MDBAccordionItem>

                                <MDBAccordionItem key={'createRmaItem'} collapseId={'createRmaItem'} headerTitle={
                                    <span style={{ color: props.textIconColour, }}
                                        className="h2 fw-bold mb-0 mx-3">{`${RMA_DETAILS_HEADER}${(isValid && rmaProduct && rmaProduct.IsADP ?' - ADP Coverage':'')}`}{isFormValid ?
                                            <FontAwesomeIcon style={{ color: props.passIconColour, }} size="1x" icon={faCheckCircle} className="mx-3" /> : ''}
                                    </span>}  >
                                    <form id={'rmaDetailsForm'}>
                                        <fieldset disabled={!isValid}>
                                            {rmaProduct && rmaProduct.SerialNumber ?
                                                <>
                                                    <MDBRow className='mb-4'>
                                                        <MDBCol>
                                                            <MDBInput wrapperClass='mb-4' label='Serial Number' id='formControlSerial' type='text' size="lg" value={rmaProduct.SerialNumber ? rmaProduct.SerialNumber : ''} disabled />
                                                        </MDBCol>
                                                        <MDBCol>
                                                            <MDBInput wrapperClass='mb-4' label='Place of Purchase' id='formControlPOP' type='text' size="lg" value={rmaProduct.PlaceOfPurchase ? rmaProduct.PlaceOfPurchase:''} disabled />
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow className='mb-4'>
                                                        <MDBCol>
                                                            <MDBInput wrapperClass='mb-4' label='Order Number' id='formControlOrderNumber' type='text' size="lg" value={rmaProduct.OrderNumber ? rmaProduct.OrderNumber:''} disabled />
                                                        </MDBCol>
                                                        <MDBCol>
                                                            <MDBInput wrapperClass='mb-4' label='Description' id='formControlDescription' type='text' size="lg" value={rmaProduct.OrderNumber ? rmaProduct.OrderNumber : ''} disabled />
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow className='mb-4'>
                                                        <MDBCol>
                                                            <MDBInput wrapperClass='mb-4' label='RMA Number' id='formControlRmaNumber' type='text' size="lg" value={rmaNumber} disabled />
                                                        </MDBCol>
                                                        <MDBCol>
                                                            <MDBInput wrapperClass='mb-4' label='Category' id='formControlCategory' type='text' size="lg" value={categoryChoice.Category} disabled />
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow className='mb-4'>
                                                        <MDBCol>
                                                            <MDBInput wrapperClass='mb-4' label='UPC' id='formControlUPCNumber' type='text' size="lg" value={rmaProduct.UPC ? rmaProduct.UPC:''} disabled />
                                                        </MDBCol>
                                                        <MDBCol>
                                                            <MDBInput wrapperClass='mb-4' label='Product Type' id='formControlProductType' type='text' size="lg" value={getProdName(rmaProduct.ProductType ? rmaProduct.ProductType:'')} disabled />
                                                        </MDBCol>
                                                    </MDBRow> </> : null}

                                            <MDBRow className='mb-4'>
                                                <MDBCol>
                                                    <Dropdown options={issues} updateChoice={updateChoice} chooseWhat={ 'Choose an Issue'} />
                                                </MDBCol>
                                                <MDBCol>
                                                    <Dropdown options={[...props.rmaTypes]} updateChoice={updateRmaChoice} chooseWhat={'Choose an RMA Type'} />
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBRow className='mb-4'>
                                                <MDBCol>
                                                    <MDBTextArea wrapperClass='mb-4' id='formDetailedDescription' maxLength={500}  onChange={updateDetailedDescription} rows={4} label='Detailed Description *' style={detailedDescriptionError ? props.errorStyle : null}
                                                    >
                                                        <div id='detailedDescriptionErrorLabel' className='form-helper'>
                                                            {detailedDescriptionError}
                                                        </div>
                                                    </MDBTextArea>
                                                </MDBCol>
                                                <MDBCol>
                                                    <MDBTextArea wrapperClass='mb-4' id='formSpecialInstructions' onChange={updateSpecialInstructions} rows={4} label='Special Instructions' />
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBBtn className='mb-4' color={props.buttonColour}  type='submit' onClick={submitRma} disabled={!isFormValid} block>
                                                { submitButtonText }
                                            </MDBBtn>
                                        </fieldset>
                                    </form>
                                </MDBAccordionItem>
                            </MDBAccordion>
                        </MDBCol>
                    </MDBRow>
                </MDBCard>
            </MDBContainer>

        </React.Fragment>
    );
}
export default CreateRma;